import React, { useState } from 'react'

const Formulario = () => {

	// ESTADO
	const [ formState, setFormState ] = useState({
		nombre: "",
		email: "",
		motivo: "",
		mensaje: ""
	});

	const [ errorMsg, setErrorMsg ] = useState('');
	const [ success, setSuccess ] = useState(null);

	// FORMULARIO
	const encode = (data) => {
		return Object.keys(data)
			.map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
			.join("&");
	}

	const enviar = (e) => {
		e.preventDefault();

		if ( validar() ) {
			//error
			return;
		}
				
		fetch("/", {
				method: "POST",
				headers: { "Content-Type": "application/x-www-form-urlencoded" },
				body: encode({ "form-name": "contacto", ...formState })
			})
			.then( (res) => {
				setSuccess(res.ok);
				console.log(res.ok);
			})
			.catch( (err) => {
				setSuccess(err.ok);
				console.log(err.ok);
			});
	}

	const validar = () => {
			
		if( formState.nombre === '' ) {
			setErrorMsg('Introduce un nombre');

			return true;
		} else { setErrorMsg('') }
		
		if( formState.email === '' ) {
			setErrorMsg('Introduce un email');
			return true;
		} else { setErrorMsg('') }
		
		if( formState.motivo === '' ) {
			setErrorMsg('Introduce el motivo de la consulta');
			return true;
		} else { setErrorMsg('') }
		
		if( formState.mensaje === '' ) {
			setErrorMsg('Introduce el mensaje de la consulta');
			return true;
		} else { setErrorMsg('') }
	}

	return (
		<>
			{	/** Si el estado de errorMsg tiene contenido muestra un mensaje*/
				errorMsg && <h5 className="alert alert-danger">{ errorMsg }</h5>
			}

			{	/** Si success es null no ha entrado en juego */
				success === null ? '' :
					/** Si es dif a true tiene un error y lo muestra, si es true la validacion a pasado y se ha enviado correctamente*/
					success === false ?
						<h5 className="alert alert-danger">Ha ocurrido un error al enviar el formulario</h5>
						:
						<h5 className="alert alert-success">El formulario se ha enviado correctamente</h5>
			}
			
			{ 
				/** Si no se ha validado nada o no tiene ningun error */
				(success === true || success === null) ?
				<form onSubmit={ (e) => enviar(e) }>
					<div className="row mb-3">
						<div className="col-12 col-lg-6">
							<label htmlFor="nombre" className="form-label fw-bold">Nombre</label>
							<input
								// required
								type="text"
								className="form-control mb-3 mb-lg-0"
								id="nombre"
								placeholder="Nombre de contacto"
								name="nombre"
								onChange={ ( e ) => { 
									setFormState({...formState, nombre: e.target.value});
									}
								}
							/>
						</div>

						<div className="col-12 col-lg-6">
							<label htmlFor="inputEmail" className="form-label fw-bold">Email</label>
							<input
								// required
								type="email"
								className="form-control"
								id="inputEmail" 	
								placeholder="ejemplo@gmail.com"
								name="email"
								onChange={ ( e ) => { 
									setFormState({...formState, email: e.target.value });
									}
								}
							/>
						</div>
					</div>
					
					<div className="mb-3 col-12">
						<label htmlFor="inputMotivo" className="form-label fw-bold">Motivo</label>
						<input
							type="text"
							className="form-control"
							id="inputMotivo"
							placeholder="Motivo de consulta"
							name="motivo"
							onChange={ ( e ) => { 
								setFormState({ ...formState, motivo: e.target.value });
							}
						}
						/>
					</div>

					<div className="mb-3 col-12">
						<label htmlFor="inputMensaje" className="form-label fw-bold">Mensaje</label>
						<textarea
							className="form-control"
							id="inputMensaje"
							rows="3"
							name="mensaje"
							onChange={ ( e ) => { 
								setFormState({ ...formState, mensaje: e.target.value });
							}
						}></textarea>
					</div>

					<div className="mb-3 col-12 col-lg-3">
						<button
							className="btn btn-primary w-100"
							type="submit"
						>Enviar</button>
					</div>
					
				</form>
				: ''
			}
		</>
	)
}

export default Formulario
