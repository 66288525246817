import React from 'react'
import LayoutBase from '../layouts/LayoutBase'
import Map from "../components/Map/Map"
import Formulario from '../components/Formulario/Formulario'
import InfoContacto from '../components/Formulario/InfoContacto'

const Contacto = () => {
    return (
        <LayoutBase>

            <header className="container-fluid">
                <div className="map">
                    <Map className="map"/>
                </div>
            </header>

            <main className="container py-5">

                <div className="row">

                    <div className="col-md-6 mt-4 mt-md-0">
                        <h3>Contactanos</h3>
                        <p>Estamos abiertos a vuestras necesidades.<br />
                        Tal vez no ofrezcamos un servicio, pero si lo necesitáis, ¡lo haremos posible!</p>
                        <Formulario />
                    </div>

                    <div className="col-md-6 order-first order-md-last">
                        <h3>Ubicación</h3>
                        <InfoContacto />
                    </div>
                </div>
                
            </main>

        </LayoutBase>
    )
}

export default Contacto
