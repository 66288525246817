import React from 'react'

const InfoContacto = () => {
	return (
		<>
			<div className="row">
				<div className="col-12">
					Lorem, ipsum dolor sit amet consectetur adipisicing elit. Vero excepturi, maiores minus esse distinctio, tenetur, sunt reiciendis ducimus amet fugit iusto consequuntur deserunt. Blanditiis velit odio, quasi magni quaerat sit.
					Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quaerat quibusdam, reiciendis et vitae iusto quam eaque consectetur non accusamus dolores odio adipisci rem ut ad dolore voluptas? Suscipit, labore perspiciatis!
				</div>
				<div className="col-12">
					Lorem, ipsum dolor sit amet consectetur adipisicing elit. Vero excepturi, maiores minus esse distinctio, tenetur, sunt reiciendis ducimus amet fugit iusto consequuntur deserunt. Blanditiis velit odio, quasi magni quaerat sit.
					Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quaerat quibusdam, reiciendis et vitae iusto quam eaque consectetur non accusamus dolores odio adipisci rem ut ad dolore voluptas? Suscipit, labore perspiciatis!
				</div>
			</div>
		</>
	)
}

export default InfoContacto
